import $ from "jquery"

$(() => {
    const breakpointWidth = 1024;
    setGalleryScrollEvents(breakpointWidth);
})

function setGalleryScrollEvents(breakpointWidth) {
    const gallery = document.querySelector(".gallery");

    gallery.addEventListener("wheel", (e) => {
        if (window.innerWidth >= breakpointWidth)
            manageScrollHorizontal(e, e.currentTarget);
    }, { passive: false });
    gallery.addEventListener("touchmove", (e) => {
        if (window.innerWidth >= breakpointWidth)
            manageScrollHorizontal(e, e.currentTarget);
    }, { passive: false });
}

function manageScrollHorizontal(e, container) {
    if (e.deltaY > 0) {
        let times = 0;
        const interval = setInterval(() => {
            container.scrollLeft += getScrollDelta();
            times++;
            if (times >= getScrollDuration())
                clearInterval(interval);
        }, 1)
        e.preventDefault();
    } else {
        let times = 0;
        const interval = setInterval(() => {
            container.scrollLeft -= getScrollDelta();
            times++;
            if (times >= getScrollDuration())
                clearInterval(interval);
        }, 1)
        e.preventDefault();
    }
}

function getBrowser() {
    if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1)
        return "firefox";
}

function getScrollDuration() {
    if(getBrowser() === "firefox")
        return 50;
    else
        return 140;
}

function getScrollDelta() {
    if(getBrowser() === "firefox")
        return 2;
    else
        return 1;
}